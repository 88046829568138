import axios from 'axios';
import React, { useContext, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom'
import { MainContext } from '../../app/App';
import { WhatsappDetailContext } from './WhatsappDetail';

function ModalRestart() {
    const { stateMain, dispatchMain } = useContext(MainContext)
    const { state, dispatch } = useContext(WhatsappDetailContext)
    const token = localStorage.getItem('whatsapp_gateway_sibermu');
    const navigate = useNavigate();

    function setToast(type, message) {
        dispatchMain({
            type: 'TOAST',
            toast: { type: type, message: message }
        })
    }

    function handleCencelClick() {
        setisSubmitting(false)
        dispatch({
            type: 'MODAL_RESTART',
            modalRestart: false
        })
    }

    function handleSubmitClcik() {
        setisSubmitting(true)
        axios({
            method: 'POST',
            url: '/api/v2/whatsapp/restart',
            responseType: 'json',
            data: { whatsapp: stateMain.whatsapp_detail.id },
            headers: { 'x-access-token': token }
        }).catch(err => {
            if (!err.response || err.code === 'ECONNABORTED') {
                setToast('error', 'Failed to connect, check your internet connection.');
            } else if (err.response) {
                if (err.response.data.code === 'invalid') {
                    setToast('error', err.response.data.errors[0] ? err.response.data.errors[0].msg : 'Internal server error.')
                } else {
                    setToast('error', err.response.data.message);
                    if (err.response.data.code === 'token') {
                        localStorage.removeItem('whatsapp_gateway_sibermu');

                        setTimeout(() => {
                            navigate('/login')
                        }, 500);
                    }
                }
            } else {
                setToast('error', err.response.data.message);
            }
        })
        setisSubmitting(false)
        handleCencelClick()
    }

    const [isSubmitting, setisSubmitting] = useState(false)

    return <Modal className="modal-dialog modal-dialog-centered" isOpen={state.modalRestart} backdrop={'static'} keyboard={true}>
        <ModalHeader toggle={handleCencelClick} charcode={<i className="fas fa-times-circle" style={{ fontSize: '20px' }}></i>}>
            Resatrt WhatsApp Gateway
        </ModalHeader>
        <ModalBody>
            <div>
                <p>Are you sure you want to restart this whatsapp gateway?</p>
                <p>*reloading process takes some time. Make sure the main device is connected to the internet</p>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button style={{ textTransform: 'capitalize' }} color="secondary" onClick={handleCencelClick}>Cancel</Button>
            <Button style={{ textTransform: 'capitalize' }} color="primary" onClick={handleSubmitClcik} disabled={isSubmitting}>{isSubmitting ? '...Restarting' : 'Restart'}</Button>
        </ModalFooter>
    </Modal>
}

export default ModalRestart