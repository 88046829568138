import axios from 'axios';
import React, { useContext, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom'
import { MainContext } from '../../app/App';
import { WhatsappDetailContext } from './WhatsappDetail';

function ModalDelete() {
    const { stateMain, dispatchMain } = useContext(MainContext)
    const { state, dispatch } = useContext(WhatsappDetailContext)
    const token = localStorage.getItem('whatsapp_gateway_sibermu');
    const navigate = useNavigate();

    function setToast(type, message) {
        dispatchMain({
            type: 'TOAST',
            toast: { type: type, message: message }
        })
    }

    const [isSubmitting, setisSubmitting] = useState(false)

    function handleCencelClick() {
        setisSubmitting(false)
        dispatch({
            type: 'MODAL_DELETE',
            modalDelete: { isOpen: false, id: null, action: '' }
        })
    }


    function deleteAdmin() {
        setisSubmitting(true)

        axios({
            method: 'DELETE',
            url: `/api/v2/whatsapp${state.modalDelete.action === 'admin' ? '/admin' : ''}`,
            responseType: 'json',
            headers: { 'x-access-token': token },
            data: { whatsapp: state.modalDelete.id }
        }).then(res => {
            setToast('success', res.data.message)

            handleCencelClick()

            if (state.modalDelete.action === 'whatsapp')
                setTimeout(() => {
                    navigate('/dashboard')
                }, 500);
            else
                dispatch({
                    type: 'TRIGGER',
                    trigger: 'get-admin'
                })

        }).catch(err => {
            if (!err.response || err.code === 'ECONNABORTED') {
                setToast('error', 'Failed to connect, check your internet connection.');
                handleCencelClick()
            } else if (err.response) {
                if (err.response.data.code === 'invalid') {
                    setToast('error', err.response.data.errors[0] ? err.response.data.errors[0].msg : 'Internal server error.')
                    handleCencelClick()
                } else {
                    setToast('error', err.response.data.message);
                    if (err.response.data.code === 'token') {
                        localStorage.removeItem('whatsapp_gateway_sibermu');

                        setTimeout(() => {
                            navigate('/login')
                        }, 500);
                    }
                }
            } else {
                setToast('error', err.response.data.message);
            }
        })
    }

    function handleSubmitClcik() {
        if (state.modalDelete.action === 'admin') deleteAdmin();
        else if (state.modalDelete.action === 'whatsapp') deleteAdmin()
    }

    return <Modal className="modal-dialog modal-dialog-centered" isOpen={state.modalDelete.isOpen} backdrop={'static'} keyboard={true}>
        <ModalHeader toggle={handleCencelClick} charcode={<i className="fas fa-times-circle" style={{ fontSize: '20px' }}></i>}>
            Delete {state.modalDelete.action === 'whatsapp' ? `WhatsApp Gateway` : 'Admin'}
        </ModalHeader>
        <ModalBody>
            <div>
                <p className='fw-bolder text-danger'>Are you sure you want to delete this data?</p>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button style={{ textTransform: 'capitalize' }} color="secondary" onClick={handleCencelClick}>Cancel</Button>
            <Button style={{ textTransform: 'capitalize' }} color="danger" onClick={handleSubmitClcik} disabled={isSubmitting}>{isSubmitting ? '...Deleting' : 'Delete'}</Button>
        </ModalFooter>
    </Modal>

}

export default ModalDelete