import axios from 'axios';
import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom'
import { MainContext } from '../../app/App';
import { WhatsappDetailContext } from './WhatsappDetail';
import CreatableSelect from 'react-select/creatable';

function ModalAddEditAdmin() {
    const { stateMain, dispatchMain } = useContext(MainContext)
    const { state, dispatch } = useContext(WhatsappDetailContext)
    const token = localStorage.getItem('whatsapp_gateway_sibermu');
    const navigate = useNavigate();

    function setToast(type, message) {
        dispatchMain({
            type: 'TOAST',
            toast: { type: type, message: message }
        })
    }

    function handleCencelClick() {
        setstateEmail({ emails: [], selected: null, isLoading: false })
        setform(initialState)
        setstateEmail({
            emails: [],
            selected: null,
            isLoading: false
        })
        dispatch({
            type: 'MODAL_ADD_EDIT_ADMIN',
            modalAddEditAdmin: { isOpen: false, data: null }
        })
    }

    const [stateEmail, setstateEmail] = useState({ emails: [], selected: null, isLoading: false })
    const handleChangeEmail = (newValue) => {
        setform(initialState)
        if (!newValue) {
            const emails = stateEmail.emails;
            const index = emails.findIndex(email => (email.is_new));
            if (index >= 0) emails.splice(index, 1)
            setstateEmail({
                ...stateEmail,
                emails: emails,
                selected: null
            })
        } else {
            setstateEmail({
                ...stateEmail,
                selected: newValue
            })
        }
    };

    const handleCreateEmail = (inputValue) => {
        if (!/^\w+(\.-]?\w+)*@\w+(\.-]?\w+)*(\.\w{2,5})+$/.test(inputValue)) {
            setform({ ...form, errEmail: 'Please enter correct email address' })
            return;
        }

        setstateEmail({
            ...stateEmail,
            isLoading: true
        })
        setTimeout(() => {
            const newEmail = {
                is_new: true,
                label: inputValue,
                value: inputValue
            };
            setstateEmail({
                ...stateEmail,
                isLoading: false,
                emails: [...stateEmail.emails, newEmail],
                selected: newEmail
            })
            setform({ ...form, errEmail: null })
        }, 500);
    };

    const initialState = {
        is_submitting: false,
        errEmail: null,
        username: '',
        errUsername: null,
        password: '',
        errPassword: null,
    }
    const [form, setform] = useState(initialState)

    function getEmail() {
        axios({
            method: 'GET',
            url: '/api/v2/whatsapp/emails',
            responseType: 'json',
            headers: { 'x-access-token': token }
        }).then(res => {
            const emails = [];
            res.data.data.forEach(data => {
                emails.push({ label: data.email, value: data.email })
            })
            setstateEmail({
                ...stateEmail,
                emails: emails
            })
        }).catch(err => {
            if (!err.response || err.code === 'ECONNABORTED') {
                setToast('error', 'Failed to connect, check your internet connection.');
                handleCencelClick()
            } else if (err.response) {
                setToast('error', err.response.data.message);
                if (err.response.data.code === 'token') {
                    localStorage.removeItem('whatsapp_gateway_sibermu');

                    setTimeout(() => {
                        navigate('/login')
                    }, 500);
                }
            }
        })
    }

    useEffect(() => {
        if (state.modalAddEditAdmin.isOpen) {
            if (state.modalAddEditAdmin.data) {
                //set data
                setform({
                    ...form,
                    username: state.modalAddEditAdmin.data.username,
                })
            } else {
                //get email
                getEmail()
            }
        }
    }, [state.modalAddEditAdmin])


    function handleInputChange(e) {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setform({
            ...form,
            [e.target.name]: e.target.value,
            errEmail: (e.target.name === 'email' ? (e.target.value ? (pattern.test(e.target.value) ? null : 'Invalid email address.') : 'Please fill in email address.') : form.errEmail),
            errUsername: (e.target.name === 'username' ? (e.target.value ? null : 'Please fill in username.') : form.errUsername),
            errPassword: (e.target.name === 'password' ? (e.target.value ? null : 'Please fill in password.') : form.errPassword)
        })
    }


    function submit(method, data) {
        setform({
            ...form,
            is_submitting: true
        })

        axios({
            method: method,
            url: '/api/v2/whatsapp/admin',
            data: data,
            headers: { 'x-access-token': token },
            responseType: 'json',
        }).then(res => {
            dispatch({
                type: 'TRIGGER',
                trigger: 'get-admin'
            })
            setToast('success', res.data.message)

            handleCencelClick()

        }).catch(err => {
            if (!err.response || err.code === 'ECONNABORTED') {
                setform({
                    ...form,
                    is_submitting: false
                })
                setToast('error', 'Failed to connect, check your internet connection.');
            } else if (err.response) {
                if (err.response.data.code === 'invalid') {
                    let whatsapp, username, email, password = null;

                    err.response.data.errors.forEach(err => {
                        whatsapp = err.param === 'whatsapp' ? err.msg : whatsapp;
                        username = err.param === 'username' ? err.msg : username;
                        email = err.param === 'email' ? err.msg : email;
                        password = err.param === 'password' ? err.msg : password;
                    });

                    if (whatsapp) setToast('error', whatsapp)

                    setform({
                        ...form,
                        is_submitting: false,
                        errEmail: email,
                        errUsername: username,
                        errPassword: password
                    })
                } else {
                    setform({
                        ...form,
                        is_submitting: false
                    })
                    setToast('error', err.response.data.message);
                    if (err.response.data.code === 'token') {
                        localStorage.removeItem('whatsapp_gateway_sibermu');

                        setTimeout(() => {
                            navigate('/login')
                        }, 500);
                    }
                }
            } else {
                setform({
                    ...form,
                    is_submitting: false
                })
                setToast('error', err.response.data.message);
            }
        })
    }

    function handleSubmitClcik() {
        if (!state.modalAddEditAdmin.data) {
            if (!form.is_submitting && form.username && stateEmail.selected && ((!state.modalAddEditAdmin.data && stateEmail.selected && stateEmail.selected.is_new) ? form.password : true)) {

                const data = {
                    whatsapp: stateMain.whatsapp_detail.id,
                    username: form.username,
                    email: stateEmail.selected.value
                }
                if (!state.modalAddEditAdmin.data && stateEmail.selected && stateEmail.selected.is_new) data.password = form.password

                // submit
                submit('POST', data)
            } else {
                setform({
                    ...form,
                    errUsername: form.username ? null : 'Please fill in username.',
                    errEmail: stateEmail.selected ? null : 'Please fill in email address.',
                    errPassword: (!state.modalAddEditAdmin.data && stateEmail.selected && stateEmail.selected.is_new) ? !form.password ? 'Please fill in password.' : null : null,
                })
            }
        } else {
            if (!form.is_submitting && form.username) {
                const data = {
                    whatsapp: state.modalAddEditAdmin.data.id,
                    username: form.username,
                }
                // submit
                submit('PUT', data)
            } else {
                setform({
                    ...form,
                    errUsername: form.username ? null : 'Pease fill in username.'
                })
            }
        }
    }

    return <Modal className="modal-dialog modal-dialog-centered" isOpen={state.modalAddEditAdmin.isOpen} backdrop={'static'} keyboard={true}>
        <ModalHeader toggle={handleCencelClick} charcode={<i className="fas fa-times-circle" style={{ fontSize: '20px' }}></i>}>
            {state.modalAddEditAdmin.data ? 'Edit ' : 'Add new'} admin
        </ModalHeader>
        <ModalBody>

            {!state.modalAddEditAdmin.data ? (
                <div className="form-group row mb-4">
                    <label htmlFor="email" className="col-form-label">Email</label>
                    <div className="input-group  has-validation">
                        <CreatableSelect
                            className={`col-12 px-0 ${form.errEmail ? 'is-invalid border border-danger rounded' : ''}`}
                            isClearable
                            isDisabled={stateEmail.isLoading}
                            isLoading={stateEmail.isLoading}
                            onChange={handleChangeEmail}
                            onCreateOption={handleCreateEmail}
                            options={stateEmail.emails}
                            value={stateEmail.selected}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.label}
                        />
                        <div id="validationEmail" className="invalid-feedback">
                            <small>{form.errEmail}</small>
                        </div>
                    </div>
                </div>
            ) : <Fragment />}

            <div className="form-group row mb-4">
                <label htmlFor="username" className="col-form-label">Username</label>
                <div className="input-group  has-validation">
                    <input type="text" className={`form-control ${form.errUsername ? 'is-invalid' : ''}`} id="username" name="username" required value={form.username} onChange={handleInputChange} placeholder="AriSuseno" aria-describedby="validationUsername" />
                    <div id="validationUsername" className="invalid-feedback">
                        <small>{form.errUsername}</small>
                    </div>
                </div>
            </div>
            {!state.modalAddEditAdmin.data && stateEmail.selected && stateEmail.selected.is_new ? (
                <div className="form-group row mb-4">
                    <label htmlFor="password" className="col-form-label">Password </label>
                    <div className="input-group  has-validation">
                        <input type="password" className={`form-control ${form.errPassword ? 'is-invalid' : ''}`} id="password" name="password" required value={form.password} onChange={handleInputChange} aria-describedby="validationPassword" />
                        <div id="validationPassword" className="invalid-feedback">
                            <small>{form.errPassword}</small>
                        </div>
                    </div>
                </div>
            ) : <Fragment />}
        </ModalBody>
        <ModalFooter>
            <Button style={{ textTransform: 'capitalize' }} color="secondary" onClick={handleCencelClick}>Cancel</Button>
            <Button style={{ textTransform: 'capitalize' }} color="primary" onClick={handleSubmitClcik} disabled={form.is_submitting}>{form.is_submitting ? '...Submitting' : 'Submit'}</Button>
        </ModalFooter>
    </Modal>
}

export default ModalAddEditAdmin