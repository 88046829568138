import React, { Fragment, useContext, } from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { makeStyles } from '@material-ui/core/styles';
import { NavbarContext } from './Navbar';
import { useNavigate } from 'react-router';
import { Icon } from '@material-ui/core';
import { MainContext } from '../../app/App';
import jwtDecode from 'jwt-decode';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        color: 'white',
        backgroundColor: ' #074f38'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
    sidebarMenu: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function NavbarMenu() {
    const { stateNavbar, dispatchNavbar } = useContext(NavbarContext);
    const { stateMain, dispatchMain } = useContext(MainContext)
    const token = localStorage.getItem('whatsapp_gateway_sibermu');

    const classes = useStyles();
    const history = useNavigate();

    const handleClick = (params) => {
        dispatchNavbar({
            type: 'DRAWER',
            drawer: false
        })

        switch (params) {
            case 'dashboard':
                history('/dashboard');
                break;
            case 'whatsapp':
                history('/whatsapp');
                break;
            case 'logout':
                dispatchMain({
                    type: 'LOGOUT',
                    message: null
                })
                break;
            default: break;
        }
    }

    return (
        <Fragment>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={<ListSubheader component="div" id="nested-list-subheader" />}
                className={classes.sidebarMenu}
            >
                <ListItem button onClick={handleClick.bind(this, "dashboard")}>
                    <ListItemIcon>
                        <DashboardRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                {
                    jwtDecode(token).role_id === 1 ? (
                        <ListItem button onClick={handleClick.bind(this, "whatsapp")}>
                            <ListItemIcon>
                                <Icon className="fab fa-whatsapp" />
                            </ListItemIcon>
                            <ListItemText primary="Whatsapp" />
                        </ListItem>
                    ) : <Fragment />
                }
                <ListItem button onClick={handleClick.bind(this, "logout")}>
                    <ListItemIcon>
                        <ExitToAppRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Keluar" />
                </ListItem>
            </List>
        </Fragment>
    )
}

export default NavbarMenu
