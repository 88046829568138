import React, { useContext, useEffect, Fragment, useState, createContext, useReducer } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { MainContext } from '../../app/App';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Navbar from '../navbar/Navbar'
import ModalAdd from './ModalAdd';

export const WhatsappContext = createContext();

function Whatsapp() {
    const { stateMain, dispatchMain } = useContext(MainContext)
    const navigate = useNavigate();
    const token = localStorage.getItem('whatsapp_gateway_sibermu');

    function setToast(type, message) {
        dispatchMain({
            type: 'TOAST',
            toast: { type: type, message: message }
        })
    }

    const initialState = {
        modal_add: false,
        trigger: ''
    }
    const reducer = (state, action) => {
        switch (action.type) {
            case 'MODAL_ADD':
                return {
                    ...state,
                    modal_add: action.modal_add
                }
            case 'TRIGGER':
                return {
                    ...state,
                    trigger: action.trigger
                }
            default: return state
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState)

    function getAdmins() {
        axios({
            method: 'GET',
            url: '/api/v2/whatsapps',
            responseType: 'json',
            headers: { 'x-access-token': token }
        }).then(res => {
            const data = res.data.data.map(wa => { return { ...wa, status: { code: null, desc: 'initialize....' }, img: './assets/img/loading.gif' } })
            setwhatsapps(data)
        }).catch(err => {
            if (!err.response || err.code === 'ECONNABORTED') {
                setToast('error', 'Failed to connect, check your internet connection.');
            } else if (err.response.data.code === 'token') {
                localStorage.removeItem('whatsapp_gateway_sibermu');

                setToast('error', err.response.data.message);

                setTimeout(() => {
                    navigate('/login')
                }, 500);
            }
        })
    }

    const [whatsapps, setwhatsapps] = useState([])
    useEffect(() => {
        getAdmins();
    }, [])

    useEffect(() => {
        if (state.trigger === 'get-admin') getAdmins()
    }, [state.trigger])

    function handleAddNew() {
        dispatch({
            type: 'MODAL_ADD',
            modal_add: true
        })
    }


    function handleAction(whatsapp) {
        dispatchMain({
            type: 'WHATSAPP_DETAIL',
            whatsapp_detail: whatsapp
        })
        navigate('/whatsapp-detail')
    }

    if (!token) return <Navigate to='/login' />
    else if (jwtDecode(token).role_id !== 1) return <Navigate to="/dashboard" />
    return (
        <Fragment>
            <div id="app">
                <div className="main-wrapper">
                    <Navbar />
                    <div className="main-content">
                        <section className="section">
                            <div className="section-header justify-content-between ">
                                <h1>WhatsApp Account</h1>
                                <div>
                                    <button className="btn btn-success m-1" style={{ textTransform: 'capitalize' }} onClick={handleAddNew}> <i className="fab fa-whatsapp"></i> Add New </button>
                                </div>
                            </div>

                            <div className="section-body col-12 p-3">
                                <table className="table table-sm table-success" style={{ tableLayout: 'fixed', }}>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th className="text-center" width="5%">No</th>
                                            <th width="12%">Name</th>
                                            <th width="13%">Phone</th>
                                            <th className="text-center">Token</th>
                                            <th className="text-center" width="10%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {whatsapps.map((whatsapp, index) => {
                                            return (
                                                <tr key={whatsapp.id}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{whatsapp.name}</td>
                                                    <td>{whatsapp.number}</td>
                                                    <td style={{ wordWrap: 'break-word' }}>{whatsapp.default_token}</td>
                                                    <td className="text-center" >
                                                        <button style={{ textTransform: 'capitalize' }} className="btn btn-sm btn-secondary m-1" onClick={handleAction.bind(this, whatsapp)} data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-bars"></i> detail</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <WhatsappContext.Provider value={{ state, dispatch }}>
                <ModalAdd />
            </WhatsappContext.Provider>
        </Fragment>
    )
}

export default Whatsapp