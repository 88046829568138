import axios from 'axios';
import React, { useContext, useEffect, Fragment, useState } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { MainContext } from '../../app/App';
import Navbar from '../navbar/Navbar'

function Dashboard() {
    const { stateMain, dispatchMain } = useContext(MainContext)
    const navigate = useNavigate();
    const token = localStorage.getItem('whatsapp_gateway_sibermu');

    function setToast(type, message) {
        dispatchMain({
            type: 'TOAST',
            toast: { type: type, message: message }
        })
    }

    function handleDetail(data) {
        dispatchMain({
            type: 'WHATSAPP_DETAIL',
            whatsapp_detail: data
        })

        navigate('/whatsapp-detail')
    }

    function getWhatsapps() {
        axios({
            method: 'GET',
            url: '/api/v2/whatsapps',
            responseType: 'json',
            headers: { 'x-access-token': token }
        }).then(res => {
            const data = res.data.data.map(wa => { return { ...wa, status: { code: null, desc: 'initialize....' }, img: './assets/img/loading.gif' } })
            setState(data)
        }).catch(err => {
            if (!err.response || err.code === 'ECONNABORTED') {
                setToast('error', 'Failed to connect, check your internet connection.');
            } else if (err.response.data.code === 'token') {
                localStorage.removeItem('whatsapp_gateway_sibermu');

                setToast('error', err.response.data.message);

                setTimeout(() => {
                    navigate('/login')
                }, 500);
            }
        })
    }


    const [state, setState] = useState([])
    useEffect(() => {
        if (token) getWhatsapps()
    }, [])

    function createSession(token) {
        if (stateMain.is_auth && stateMain.socket) {
            stateMain.socket.emit('create-session', {
                token: token
            })
        }
    }

    // get whatsapp session
    useEffect(() => {
        if (stateMain.socket && stateMain.is_auth) {
            state.forEach(whatsapp => {
                createSession(whatsapp.default_token)
            })
        }
    }, [state])


    // callback from socket 
    useEffect(() => {
        if (stateMain.from_socket) {
            const accounts = state;
            const index = accounts.findIndex(data => data.code === stateMain.from_socket.value.id)
            if (stateMain.from_socket.key === 'qrcode' && index >= 0) {
                accounts[index].status = { code: 0, desc: 'Scan QRCode' };
                accounts[index].img = stateMain.from_socket.value.qrcode
                setState(accounts)

                setRender(accounts)
            } else if (stateMain.from_socket.key === 'ready' && index >= 0) {
                accounts[index].connected_to = stateMain.from_socket.value.number ?? accounts[index].connected_to;
                accounts[index].status = { code: 1, desc: 'Ready' };
                accounts[index].img = './assets/img/whatsapp-icon.png';
                setState(accounts)

                setRender(accounts)

                const isMatch = accounts[index].connected_to === accounts[index].number
                setToast(isMatch ? 'success' : 'warning', `${accounts[index].name} was connected ${!isMatch ? 'to different number.' : '.'}`)
            } else if (stateMain.from_socket.key === 'disconnected' && index >= 0) {
                accounts[index].status = { code: 1, desc: 'Disconnected' };
                accounts[index].img = './assets/img/whatsapp-icon-red.png';
                setState(accounts)

                setRender(accounts)

                setToast('error', stateMain.from_socket.value.message)
            } else if (stateMain.from_socket.key === 'restarted' && index >= 0) {
                accounts[index].status = { code: 0, desc: 'Restart' };
                accounts[index].img = './assets/img/loading.gif';
                setState(accounts)

                setRender(accounts)

                setToast('info', accounts[index].name + ' is restarting, please wait..')
            }
        }
    }, [stateMain.from_socket])


    //render
    function setRender(accounts) {
        const card = [];
        accounts.forEach(whatsapp => {
            card.push(
                <div key={whatsapp.code} className="col" onClick={handleDetail.bind(this, whatsapp)} style={{ cursor: 'pointer' }}>
                    <div className="card">
                        <div className="card-img d-flex justify-content-center p-3">
                            <img src={whatsapp.img} style={{ maxWidth: '150px' }} className="card-img-top " alt="..." />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">{whatsapp.name}</h5>
                            <p className="card-text mb-0">status : {whatsapp.status.desc}</p>
                            <p className="card-text">phone : <span className={whatsapp.status.code ? (whatsapp.connected_to !== whatsapp.number ? 'text-danger font-weight-bold' : 'text-success') : 'text-dark'}>{whatsapp.number}</span> </p>
                        </div>
                    </div>
                </div>)
        })
        setrender(card)
    }
    const [render, setrender] = useState(<Fragment />)

    useEffect(() => {
        setRender(state)
    }, [state])

    if (!token) return <Navigate to='/login' />
    return (
        <div id="app">
            <div className="main-wrapper">
                <Navbar />
                <div className="main-content">
                    <section className="section">
                        <div className="section-header justify-content-between ">
                            <h1>Dashboard </h1>
                        </div>
                        <div className="section-body col-12 p-0">
                            <div className="row mx-4 my-0">
                                <div className="row row-cols-1 row-cols-lg-5 row-cols-md-3 g-4">
                                    {render}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Dashboard