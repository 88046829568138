import React, { Fragment, useReducer, useContext } from 'react'
import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { MainContext } from '../../app/App';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import NavbarMenu from './NavbarMenu';
import jwtDecode from 'jwt-decode';

export const NavbarContext = createContext();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        color: 'white',
        backgroundColor: ' #074f38'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: 300,
    },
    sidebarMenu: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


function Navbar() {
    const { stateMain, dispatchMain } = useContext(MainContext)
    const history = useNavigate();
    const token = localStorage.getItem('whatsapp_gateway_sibermu');

    const handleClose = () => {
        dispatchNavbar({
            type: 'ANCHOR',
            anchor: null
        })
    }
    const handleMenu = (event) => {
        dispatchNavbar({
            type: 'ANCHOR',
            anchor: event.currentTarget
        })
    }
    const handleMenuIcon = (page) => {
        if (page === 'logout') {
            dispatchMain({
                type: 'LOGOUT',
            })

            history('/login');
        }
        handleClose();
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        dispatchNavbar({
            type: 'DRAWER',
            drawer: open
        })
    };


    const initialReducer = {
        drawer: false,
        anchor: null
    }
    const reducer = (state, action) => {
        switch (action.type) {
            case 'DRAWER':
                return {
                    ...state,
                    drawer: action.drawer
                }
            case 'ANCHOR':
                return {
                    ...state,
                    anchor: action.anchor
                }
            default:
                return state
        }
    }
    const [stateNavbar, dispatchNavbar] = useReducer(reducer, initialReducer)

    const classes = useStyles();
    const open = Boolean(stateNavbar.anchor);

    return (
        <Fragment>
            <AppBar className={classes.root} position="static">
                <Toolbar>
                    <IconButton onClick={toggleDrawer(true)} edge="start" className={classes.menuButton} style={{ textDecoration: 'none', color: 'white' }} aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title} style={{ cursor: 'pointer' }} onClick={(e) => history('/dashboard')}>
                        Whatsapp Gateway
                    </Typography>
                    <div>
                        <Typography style={{ cursor: 'pointer' }} onClick={handleMenu}>{token ? jwtDecode(token).username : 'Admin'}</Typography>
                    </div>
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            style={{ textDecoration: 'none', color: 'white' }}
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={stateNavbar.anchor}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleMenuIcon.bind(this, 'logout')}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer anchor={'left'} open={stateNavbar.drawer} onClose={toggleDrawer(false)} >
                <div>
                    <img src="./assets/img/usm-logo.png" alt="logo" width="200" className="m-4" />
                    <Divider />
                    <div
                        className={clsx(classes.list)}
                        role="presentation"
                        onKeyDown={toggleDrawer(false)}
                    >
                        <NavbarContext.Provider value={{ stateNavbar, dispatchNavbar }}>
                            <NavbarMenu />
                        </NavbarContext.Provider>
                    </div>
                </div >
            </Drawer>
        </Fragment>
    )
}

export default Navbar