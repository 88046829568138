import React, { useState, useContext, Fragment } from 'react'
import { Navigate } from 'react-router-dom';
import { MainContext } from '../../app/App';
import axios from 'axios';

function Login() {
    const { stateMain, dispatchMain } = useContext(MainContext)

    const token = localStorage.getItem('whatsapp_gateway_sibermu');

    const initialState = {
        email: '',
        errEmail: null,
        password: '',
        errPassword: null,
        is_submitting: false
    }
    const [state, setstate] = useState(initialState)

    function setToast(type, message) {
        dispatchMain({
            type: 'TOAST',
            toast: { type: type, message: message }
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        if (state.email && state.password) {
            axios({
                method: 'POST',
                url: '/api/v2/auth/signin',
                responseType: 'json',
                data: {
                    email: state.email,
                    password: state.password
                }
            }).then(res => {
                // dispatchMain({
                //     type: 'IS_AUTH',
                //     is_auth: true
                // });

                // setstate(initialState);

                localStorage.setItem('whatsapp_gateway_sibermu', res.data.token)

                // navigate('/dashboard')

                window.location.reload();
            }).catch(err => {
                const setState = {
                    ...state,
                    password: '',
                    is_submitting: false
                }
                if (!err.response || err.code === 'ECONNABORTED') {
                    setToast('error', 'Failed to connect, check your internet connection.');
                } else if (err.response.data.code === 'invalid') {
                    let email = null;
                    let pass = null;

                    err.response.data.errors.forEach(err => {
                        email = err.param === 'email' ? err.msg : email;
                        pass = err.param === 'password' ? err.msg : pass;
                    });
                    setState.errEmail = email;
                    setState.errPassword = pass;
                } else {
                    setToast('error', err.response.data.message);
                }

                setstate(setState)
            })
        } else {
            setstate({
                ...state,
                errPassword: state.password ? null : 'Please fill in password.',
                errEmail: state.email ? null : 'Please fill in email address.'
            })
        }
    }

    function handleInputChange(e) {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        setstate({
            ...state,
            [e.target.name]: e.target.value,
            errEmail: (e.target.name === 'email' ? (e.target.value ? (pattern.test(e.target.value) ? null : 'Invalid email address.') : 'Please fill in your email.') : state.errEmail),
            errPassword: (e.target.name === 'password' ? (e.target.value ? null : 'Please fill in your password') : state.errPassword)
        })
    }

    if (token) return <Navigate to="/dashboard" />

    return (
        <Fragment>
            <div id="app">
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="login-brand">
                                    <img src="./assets/img/usm-logo.png" alt="logo" width="250px" />
                                </div>
                                <div className="card card-primary">
                                    <div className="card-header"><h4 className="fs-2">Login</h4></div>

                                    <div className="card-body">
                                        <form method="POST" className="needs-validation" noValidate="" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="email" className="form-label fs-6">Email</label>
                                                <input type="email" className={`form-control mb-0 ${state.errEmail !== null ? 'is-invalid' : ''}`} id="email" name="email" autoFocus value={state.email} onChange={handleInputChange} />
                                                <small className="text-danger">{state.errEmail !== null ? state.errEmail : ''}</small>
                                            </div>
                                            <div className={`form-group ${state.errPassword !== null ? 'pb-4' : ''}`}>
                                                <label htmlFor="password" className="form-label fs-6">Password</label>
                                                <input type="password" className={`form-control mb-0 ${state.errPassword !== null ? 'is-invalid' : ''}`} id="password" name="password" value={state.password} onChange={handleInputChange} />
                                                <small className="text-danger">{state.errPassword !== null ? state.errPassword : ''}</small>
                                            </div>

                                            <div className="form-group ">
                                                <button type="submit" disabled={state.is_submitting} className="btn btn-success btn-lg btn-block" >
                                                    Login
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="simple-footer">
                                    Copyright &copy; {new Date().getFullYear()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default Login