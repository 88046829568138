import React, { useContext, useEffect, Fragment, useState, createContext, useReducer } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { MainContext } from '../../app/App';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import Navbar from '../navbar/Navbar'
import ModalAddEditAdmin from './ModalAddEditAdmin';
import ModalDelete from './ModalDelete';
import ModalEditWA from './ModalEditWA';
import ModalRestart from './ModalRestart';
import ModalToken from './ModalToken';

export const WhatsappDetailContext = createContext();

function WhatsappDetail() {
    const { stateMain, dispatchMain } = useContext(MainContext)
    const navigate = useNavigate();
    const token = localStorage.getItem('whatsapp_gateway_sibermu');

    const [special_access, setSpecial_access] = useState(false)

    const initialState = {
        modalEditWA: false,
        modalToken: false,
        modalRestart: false,
        modalAddEditAdmin: { isOpen: false, data: null },
        modalDelete: { isOpen: false, id: null, action: '' },
        trigger: '',
    }
    const reducer = (state, action) => {
        switch (action.type) {
            case 'MODAL_EDIT_WA':
                return {
                    ...state,
                    modalEditWA: action.modalEditWA
                }
            case 'MODAL_TOKEN':
                return {
                    ...state,
                    modalToken: action.modalToken
                }
            case 'MODAL_RESTART':
                return {
                    ...state,
                    modalRestart: action.modalRestart
                }
            case 'MODAL_ADD_EDIT_ADMIN':
                return {
                    ...state,
                    modalAddEditAdmin: action.modalAddEditAdmin
                }
            case 'MODAL_DELETE':
                return {
                    ...state,
                    modalDelete: action.modalDelete
                }
            case 'TRIGGER':
                return {
                    ...state,
                    trigger: action.trigger
                }
            default: return state
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState)

    function setToast(type, message) {
        dispatchMain({
            type: 'TOAST',
            toast: { type: type, message: message }
        })
    }

    function handleEditWA() {
        dispatch({
            type: 'MODAL_EDIT_WA',
            modalEditWA: true
        })
    }
    function handleNewToken() {
        dispatch({
            type: 'MODAL_TOKEN',
            modalToken: true
        })
    }
    function handleRestartWA() {
        dispatch({
            type: 'MODAL_RESTART',
            modalRestart: true
        })
    }
    function handleAddAdmin(data) {
        dispatch({
            type: 'MODAL_ADD_EDIT_ADMIN',
            modalAddEditAdmin: { isOpen: true, data: data }
        })
    }

    const [admins, setAdmins] = useState([]);
    useEffect(() => {
        if (token) getAdmin();
    }, [])


    useEffect(() => {
        if (state.trigger === 'get-admin') getAdmin();
    }, [state.trigger])


    function getAdmin() {
        if (token && stateMain.whatsapp_detail)
            axios({
                method: 'GET',
                url: `/api/v2/whatsapp/admins?whatsapp=${stateMain.whatsapp_detail.id}`,
                headers: { 'x-access-token': token },
                responseType: 'json'
            }).then(res => {
                setAdmins(res.data.data)

                setSpecial_access(res.data.data.find(data => parseInt(data.user_id) === parseInt(jwtDecode(token).id)) ? true : false)

                dispatch({
                    type: 'TRIGGER',
                    trigger: ''
                })
            }).catch(err => {
                if (!err.response || err.code === 'ECONNABORTED') {
                    setToast('error', 'Failed to connect, check your internet connection.');
                } else if (err.response) {
                    if (err.response.data.code === 'invalid') {
                        setToast('error', err.response.data.errors[0] ? err.response.data.errors[0].msg : 'Internal server error.')
                    } else {
                        setToast('error', err.response.data.message);
                        if (err.response.data.code === 'token') {
                            localStorage.removeItem('whatsapp_gateway_sibermu');

                            setTimeout(() => {
                                navigate('/login')
                            }, 500);
                        }
                    }
                } else {
                    setToast('error', err.response.data.message);
                }
            })
    }

    function handleDeleteAdmin(id) {
        dispatch({
            type: 'MODAL_DELETE',
            modalDelete: { isOpen: true, id: id, action: 'admin' }
        })
    }

    function handleDeleteWA() {
        dispatch({
            type: 'MODAL_DELETE',
            modalDelete: { isOpen: true, id: stateMain.whatsapp_detail.id, action: 'whatsapp' }
        })
    }

    // callback from socket 
    useEffect(() => {
        if (stateMain.from_socket) {
            if (stateMain.from_socket.value.id === stateMain.whatsapp_detail.code) {
                const accounts = stateMain.whatsapp_detail;
                if (stateMain.from_socket.key === 'qrcode') {
                    accounts.status = { code: 0, desc: 'Scan QRCode' };
                    accounts.img = stateMain.from_socket.value.qrcode
                } else if (stateMain.from_socket.key === 'ready') {
                    accounts.connected_to = stateMain.from_socket.value.number ?? accounts.connected_to;
                    accounts.status = { code: 1, desc: 'Ready' };
                    accounts.img = './assets/img/whatsapp-icon.png';

                    const isMatch = accounts.connected_to === accounts.number
                    setToast(isMatch ? 'success' : 'warning', `${accounts.name} was connected ${!isMatch ? 'to different number.' : '.'}`)
                } else if (stateMain.from_socket.key === 'disconnected') {
                    accounts.status = { code: 1, desc: 'Disconnected' };
                    accounts.img = './assets/img/whatsapp-icon-red.png';
                    setToast('error', stateMain.from_socket.value.message)
                }
                else if (stateMain.from_socket.key === 'restarted') {
                    accounts.status = { code: 0, desc: 'Restart' };
                    accounts.img = './assets/img/loading.gif';

                    setToast('info', accounts.name + ' is restarting, please wait..')
                }

                dispatchMain({
                    type: 'WHATSAPP_DETAIL',
                    whatsapp_detail: accounts
                })
            }
        }
    }, [stateMain.from_socket])


    if (!token) return <Navigate to='/login' />
    else if (!stateMain.whatsapp_detail) return <Navigate to='/dashboard' />

    return (
        <Fragment>
            <div id="app">
                <div className="main-wrapper">
                    <Navbar />
                    <div className="main-content">
                        <section className="section">
                            <div className="section-header justify-content-between ">
                                <h1>WhatsApp Gateway Detail</h1>
                                <div>
                                    <button className="btn btn-secondary m-1" style={{ textTransform: 'capitalize' }} onClick={handleEditWA}> <i className="fa fa-edit"></i> Edit</button>
                                    {special_access ? <button className="btn btn-success m-1" style={{ textTransform: 'capitalize' }} onClick={handleNewToken}> <i className="fas fa-key"></i> New Token </button> : <Fragment />}
                                    {special_access ? stateMain.whatsapp_detail.status.code ? (<button className="btn btn-primary m-1" style={{ textTransform: 'capitalize' }} onClick={handleRestartWA}> <i className="fa fa-redo"></i> Restart</button>) : <Fragment /> : <Fragment />}
                                    {jwtDecode(token).role_id === 1 ? (<button className="btn btn-danger m-1" style={{ textTransform: 'capitalize' }} onClick={handleDeleteWA}> <i className="fa fa-trash"></i> Delete</button>) : <Fragment />}
                                </div>
                            </div>

                            <div className="section-body col-12 p-3">
                                <div className="card-body">
                                    <div className="empty-state mb-5 pt-0">
                                        <div className="container">
                                            <div className="row justify-content-md-center">
                                                <div className="col-10">
                                                    <img className="gallery-item mb-3" style={{ maxWidth: '220px' }} src={stateMain.whatsapp_detail.img} data-title="QRCode" alt="status"></img>
                                                    <h1 className="font-weight-bold">{stateMain.whatsapp_detail.name}</h1>
                                                    <p className="font-weight-bold m-0">Status : {stateMain.whatsapp_detail.status.desc}</p>
                                                    <p className="font-weight-bold m-0">Phone : {stateMain.whatsapp_detail.number}</p>
                                                    {stateMain.whatsapp_detail.number !== stateMain.whatsapp_detail.connected_to ? (<p className="font-weight-bold m-0">Connect To : {stateMain.whatsapp_detail.connected_to ? stateMain.whatsapp_detail.connected_to : '-'}</p>) : ''}
                                                    <p className="font-weight-bold m-0" style={{ overflowWrap: 'break-word' }}>Token : {stateMain.whatsapp_detail.default_token}</p>
                                                </div>
                                            </div>
                                            <div className="pt-5">
                                                <button style={{ textTransform: 'capitalize' }} className="btn btn-success mb-3 float-end" onClick={handleAddAdmin.bind(this, null)}><i className="fa fa-user-plus"></i> Add Admin</button>
                                                <table className="table table-sm table-striped table-success">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th className="text-center" scope="col" width="30px">No</th>
                                                            <th className="text-center" scope="col" width="30%">Name</th>
                                                            <th className="text-center" scope="col" >Email</th>
                                                            <th className="text-center" scope="col" width="300px">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {admins.map((admin, index) => {
                                                            return <tr key={index}>
                                                                <th className="text-center" scope="row">{index + 1}</th>
                                                                <td className="text-left">{admin.username}</td>
                                                                <td className="text-left">{admin.email}</td>
                                                                <td className="text-center">
                                                                    <button className="btn btn-secondary btn-sm mx-1" onClick={handleAddAdmin.bind(this, admin)}> <i className="fa fa-edit"></i> Edit</button>
                                                                    <button className="btn btn-danger btn-sm mx-1" onClick={handleDeleteAdmin.bind(this, admin.id)}> <i className="fa fa-eraser"></i> Delete</button>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <WhatsappDetailContext.Provider value={{ state, dispatch }}>
                <ModalEditWA />
                <ModalToken />
                <ModalRestart />
                <ModalAddEditAdmin />
                <ModalDelete />
            </WhatsappDetailContext.Provider>
        </Fragment>
    )
}

export default WhatsappDetail