import axios from 'axios';
import React, { useContext, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom'
import { MainContext } from '../../app/App';
import { WhatsappDetailContext } from './WhatsappDetail';

function ModalEditWA() {
    const { stateMain, dispatchMain } = useContext(MainContext)
    const { state, dispatch } = useContext(WhatsappDetailContext)
    const token = localStorage.getItem('whatsapp_gateway_sibermu');
    const navigate = useNavigate();

    function setToast(type, message) {
        dispatchMain({
            type: 'TOAST',
            toast: { type: type, message: message }
        })
    }

    function handleCencelClick() {
        setdataForm(initialDataFrom)
        dispatch({
            type: 'MODAL_EDIT_WA',
            modalEditWA: false
        })
    }

    function handleSubmitClcik() {
        if (!dataForm.isSubmitting && dataForm.name && dataForm.phone) {
            const data = {
                whatsapp: stateMain.whatsapp_detail.id,
                name: dataForm.name,
                phone: dataForm.phone
            }
            axios({
                method: 'PUT',
                url: '/api/v2/whatsapp',
                responseType: 'json',
                data: data,
                headers: { 'x-access-token': token }
            }).then(res => {
                setToast('success', res.data.message);

                const detail = stateMain.whatsapp_detail;
                detail.name = dataForm.name
                detail.number = dataForm.phone
                dispatchMain({
                    type: 'WHATSAPP_DETAIL',
                    whatsapp_detail: detail
                })

                handleCencelClick()
            }).catch(err => {
                if (!err.response || err.code === 'ECONNABORTED') {
                    setdataForm({
                        ...dataForm,
                        isSubmitting: false
                    })
                    setToast('error', 'Failed to connect, check your internet connection.');
                } else if (err.response) {
                    if (err.response.data.code === 'invalid') {
                        let whatsapp, name, phone = null;

                        err.response.data.errors.forEach(err => {
                            whatsapp = err.param === 'whatsapp' ? err.msg : whatsapp;
                            name = err.param === 'name' ? err.msg : name;
                            phone = err.param === 'phone' ? err.msg : phone;
                        });

                        if (whatsapp) setToast('error', whatsapp)

                        setdataForm({
                            ...dataForm,
                            isSubmitting: false,
                            errName: name,
                            errPhone: phone
                        })
                    } else {
                        setToast('error', err.response.data.message);
                        if (err.response.data.code === 'token') {
                            localStorage.removeItem('whatsapp_gateway_sibermu');

                            setTimeout(() => {
                                navigate('/login')
                            }, 500);
                        }
                    }
                } else {
                    setToast('error', err.response.data.message);
                }
            })
        } else {
            setdataForm({
                ...dataForm,
                errName: dataForm.name ? null : 'Please fill in WhatsApp Gateway name.',
                errPhone: dataForm.phone ? null : 'Please fill in WhatsApp Gtateway phone number.'
            })
        }
    }


    function handleInputChange(e) {
        e.preventDefault();
        if (e.target.validity.valid) {
            setdataForm({
                ...dataForm,
                [e.target.name]: e.target.name === 'phone' ? e.target.value.replace(' ', '') : e.target.value,
                errName: e.target.name === 'name' && !e.target.value ? 'Please fill in WhatsApp Gtateway name.' : dataForm.errName,
                errPhone: e.target.name === 'phone' ? (e.target.value ? (e.target.value[0] === '6' && e.target.value[1] === '2' ? null : 'Please use the 628xxx format.') : 'Please fill in WhatsApp Gtateway phone number.') : dataForm.errPhone
            })
        }
    }

    const initialDataFrom = {
        isSubmitting: false,
        name: stateMain.whatsapp_detail ? stateMain.whatsapp_detail.name : '',
        errName: null,
        phone: stateMain.whatsapp_detail ? stateMain.whatsapp_detail.number : '',
        errPhone: null
    }
    const [dataForm, setdataForm] = useState(initialDataFrom)


    return <Modal className="modal-dialog modal-dialog-centered" isOpen={state.modalEditWA} backdrop={'static'} keyboard={true}>
        <ModalHeader toggle={handleCencelClick} charcode={<i className="fas fa-times-circle" style={{ fontSize: '20px' }}></i>}>
            Edit WhastApp Gateway
        </ModalHeader>
        <ModalBody>
            <form>
                <div className="form-group row mb-4">
                    <label htmlFor="name" className="col-form-label">Name</label>
                    <div className="input-group  has-validation">
                        <input type="text" className={`form-control ${dataForm.errName ? 'is-invalid' : ''}`} id="name" name="name" autoFocus value={dataForm.name} onChange={handleInputChange} placeholder="LSI UMY" aria-describedby="validationName" />
                        <div id="validationName" className="invalid-feedback">
                            <small>{dataForm.errName}</small>
                        </div>
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <label htmlFor="phone" className="col-form-label">Number </label>
                    <div className="input-group  has-validation">
                        <input pattern="[0-9]*" type="text" className={`form-control ${dataForm.errPhone ? 'is-invalid' : ''}`} id="phone" name="phone" value={dataForm.phone} onChange={handleInputChange} placeholder="628xxxx" aria-describedby="validationPhone" />
                        <div id="validationPhone" className="invalid-feedback">
                            <small>{dataForm.errPhone}</small>
                        </div>
                    </div>
                </div>
            </form>
        </ModalBody>
        <ModalFooter>
            <Button style={{ textTransform: 'capitalize' }} color="secondary" onClick={handleCencelClick}>Cancel</Button>
            <Button style={{ textTransform: 'capitalize' }} color="primary" onClick={handleSubmitClcik} disabled={dataForm.isSubmitting}> {dataForm.isSubmitting ? '...Submitting' : 'Submit'} </Button>
        </ModalFooter>
    </Modal>
}

export default ModalEditWA