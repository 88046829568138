import axios from 'axios';
import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom'
import { MainContext } from '../../app/App';
import CreatableSelect from 'react-select/creatable';
import { WhatsappContext } from './Whatsapp';

function ModalAdd() {
    const { stateMain, dispatchMain } = useContext(MainContext)
    const { state, dispatch } = useContext(WhatsappContext)
    const token = localStorage.getItem('whatsapp_gateway_sibermu');
    const navigate = useNavigate();

    function setToast(type, message) {
        dispatchMain({
            type: 'TOAST',
            toast: { type: type, message: message }
        })
    }

    function handleCencelClick() {
        setform(initailState)
        dispatch({
            type: 'MODAL_ADD',
            modal_add: false
        })
    }

    function handleInputChange(e) {
        if (e.target.validity.valid) {
            setform({
                ...form,
                [e.target.name]: e.target.name === 'code' ? e.target.value.replace(' ', '').toLowerCase() : (e.target.name === 'phone' ? e.target.value.replace(' ', '') : e.target.value),
                errCode: e.target.name === 'code' ? !e.target.value ? 'Pleasee fill in WhatsApp code.' : null : form.errCode,
                errName: e.target.name === 'name' ? !e.target.value ? 'Pleasee fill in WhatsApp name.' : null : form.errName,
                errPhone: e.target.name === 'phone' ? (e.target.value[0] !== '6' && e.target.value[1] !== '2') ? 'Please use the 628xxx format.' : null : form.errPhone
            })
        }
    }

    function handleSubmitClcik() {
        if (!form.is_submitting && form.code && form.name && form.phone) {
            setform({
                ...form,
                is_submitting: true
            })
            const data = {
                name: form.name,
                phone: form.phone,
                code: form.code
            }
            axios({
                method: 'POST',
                url: '/api/v2/whatsapp',
                headers: { 'x-access-token': token },
                data: data,
                responseType: 'json'
            }).then(res => {
                setToast('success', res.data.message)
                handleCencelClick()

                dispatch({
                    type: 'TRIGGER',
                    trigger: 'get-admin'
                })
            }).catch(err => {
                if (!err.response || err.code === 'ECONNABORTED') {
                    setform({
                        ...form,
                        is_submitting: false
                    })
                    setToast('error', 'Failed to connect, check your internet connection.');
                } else if (err.response) {
                    if (err.response.data.code === 'invalid') {
                        let name, phone, code = null;

                        err.response.data.errors.forEach(err => {
                            name = err.param === 'name' ? err.msg : name;
                            phone = err.param === 'phone' ? err.msg : phone;
                            code = err.param === 'code' ? err.msg : code;
                        });

                        setform({
                            ...form,
                            is_submitting: false,
                            errCode: code,
                            errName: name,
                            errPhone: phone
                        })
                    } else {
                        setform({
                            ...form,
                            is_submitting: false
                        })
                        setToast('error', err.response.data.message);
                        if (err.response.data.code === 'token') {
                            localStorage.removeItem('whatsapp_gateway_sibermu');

                            setTimeout(() => {
                                navigate('/login')
                            }, 500);
                        }
                    }
                } else {
                    setform({
                        ...form,
                        is_submitting: false
                    })
                    setToast('error', err.response.data.message);
                }
            })
        } else {
            setform({
                ...form,
                errCode: form.code ? form.errCode : 'Please fill in WhatsApp ode.',
                errName: form.name ? form.errName : 'Please fill in WhatsApp name.',
                errPhone: form.phone ? form.errPhone : 'Please fill in WhatsApp number.',
            })
        }
    }

    const initailState = {
        is_submitting: false,
        name: '',
        code: '',
        phone: '',
        errName: null,
        errCode: null,
        errPhone: null
    }
    const [form, setform] = useState(initailState)

    return <Modal className="modal-dialog modal-dialog-centered" isOpen={state.modal_add} backdrop={'static'} keyboard={true}>
        <ModalHeader toggle={handleCencelClick} charcode={<i className="fas fa-times-circle" style={{ fontSize: '20px' }}></i>}>
            Add WhatsApp Gateway Account
        </ModalHeader>
        <ModalBody>
            <form>
                <div className="form-group row mb-3">
                    <label htmlFor="name" className="col-form-label">Whatsapp Name</label>
                    <div className="input-group  has-validation">
                        <input type="text" className={`form-control ${form.errName ? 'is-invalid' : ''}`} id="name" name="name" autoFocus value={form.name} onChange={handleInputChange} placeholder="Customer Service" aria-describedby="validationName" />
                        <div id="validationName" className="invalid-feedback">
                            <small>{form.errName}</small>
                        </div>
                    </div>
                </div>
                <div className="form-group row mb-2">
                    <label htmlFor="name" className="col-form-label">Unique Code</label>
                    <div className="input-group  has-validation">
                        <input type="text" className={`form-control ${form.errCode ? 'is-invalid' : ''}`} id="code" name="code" value={form.code} onChange={handleInputChange} placeholder="cs_sibermu" aria-describedby="validationCode" />
                        <div id="validationCode" className="invalid-feedback">
                            <small>{form.errCode}</small>
                        </div>
                    </div>
                    <div className={form.errCode ? 'mt-3' : 'mt-0'}>
                        <small className="text-danger">*make sure code is correct, the code cannot be replaced once it is created.</small>
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <label htmlFor="phone" className="col-form-label">Number</label>
                    <div className="input-group  has-validation">
                        <input pattern="[0-9]*" type="text" className={`form-control ${form.errPhone ? 'is-invalid' : ''}`} id="phone" name="phone" value={form.phone} onChange={handleInputChange} placeholder="628xxxx" aria-describedby="validationPhone" />
                        <div id="validationPhone" className="invalid-feedback">
                            <small>{form.errPhone}</small>
                        </div>
                    </div>
                </div>

            </form>
        </ModalBody>
        <ModalFooter>
            <Button style={{ textTransform: 'capitalize' }} color="secondary" onClick={handleCencelClick}>Cancel</Button>
            <Button style={{ textTransform: 'capitalize' }} color="primary" onClick={handleSubmitClcik} disabled={form.is_submitting}>{form.is_submitting ? '...Submitting' : 'Submit'}</Button>
        </ModalFooter>
    </Modal>
}

export default ModalAdd