import React, { createContext, useReducer, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import SimpleReactLightbox from 'simple-react-lightbox'
import io from "socket.io-client";
import Login from '../components/auth/Login';
import Dashboard from '../components/dashboard/Dashboard';
import Whatsapp from '../components/whatsapp/Whatsapp';
import WhatsappDetail from '../components/whatsappDetail/WhatsappDetail';

const CONNECTION_PORT = "https://wa-gateway.sibermu.ac.id/";

export const MainContext = createContext()

function App() {
  const initialState = {
    is_auth: localStorage.getItem('whatsapp_gateway_sibermu') ? true : false,
    socket: null,
    from_socket: null,
    toast: null,
    whatsapp_detail: null
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SOCKET':
        return {
          ...state,
          socket: action.socket
        }
      case 'IS_AUTH':
        return {
          ...state,
          is_auth: action.is_auth
        }
      case 'TOAST':
        return {
          ...state,
          toast: action.toast
        }
      case 'FROM_SOCKET':
        return {
          ...state,
          from_socket: action.from_socket
        }
      case 'WHATSAPP_DETAIL':
        return {
          ...state,
          whatsapp_detail: action.whatsapp_detail
        }
      case 'LOGOUT':
        localStorage.removeItem('whatsapp_gateway_sibermu')
        return {
          is_auth: false,
          socket: null,
        }
      default: return state;
    }
  }

  const [stateMain, dispatchMain] = useReducer(reducer, initialState)

  useEffect(() => {
    if (stateMain.is_auth) {
      if (!stateMain.socket) {
        dispatchMain({
          type: 'SOCKET',
          socket: io(CONNECTION_PORT, { query: `user=${localStorage.getItem('whatsapp_gateway_sibermu')}` })
        })
      }
    } else {
      dispatchMain({
        type: 'SOCKET',
        socket: null
      })
    }
  }, [stateMain.is_auth]);


  const [handleSocket, sethandleSocket] = useState(null)
  useEffect(() => {
    if (stateMain.socket) {
      stateMain.socket.on('token', function (data) {
        sethandleSocket({ key: 'token', value: data })
      })
      stateMain.socket.on('qrcode', function (data) {
        sethandleSocket({ key: 'qrcode', value: data })
      })
      stateMain.socket.on('ready', function (data) {
        sethandleSocket({ key: 'ready', value: data })
      })
      stateMain.socket.on('disconnected', function (data) {
        sethandleSocket({ key: 'disconnected', value: data })
      })
      stateMain.socket.on('restarted', function (data) {
        sethandleSocket({ key: 'restarted', value: data })
      })
    }
  }, [stateMain.socket])

  useEffect(() => {
    if (handleSocket && handleSocket.key) {
      dispatchMain({
        type: 'FROM_SOCKET',
        from_socket: handleSocket
      })
    }

    return () => {
      sethandleSocket(null)
    }
  }, [handleSocket])



  useEffect(() => {
    if (stateMain.toast && stateMain.toast.type) {
      toast(stateMain.toast.message, {
        type: stateMain.toast.type,
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }, [stateMain.toast])


  return (
    <Router>
      <SimpleReactLightbox>
        <MainContext.Provider value={{ stateMain, dispatchMain }}>
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route exect path="/login" element={<Login />} />
            <Route exect path="/dashboard" element={<Dashboard />} />
            <Route exect path="/whatsapp-detail" element={< WhatsappDetail />} />
            <Route exect path="/whatsapp" element={< Whatsapp />} />
          </Routes>
        </MainContext.Provider>
      </SimpleReactLightbox>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default App;
